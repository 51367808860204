<template>
	<div class='a-card' :class='{"onlyRead": onlyRead}'>
		<div class='i-card-wrap'>
			<div class='i-card-container'>
				<div class='i-card-image'>
					<img :src="`https://api.easytour.club/api${item.logo}`">
				</div>
				<div class='i-card-content'>
					<p class='i-card-title'>{{item.name}}</p>
					<ul class='a-list default i-card-list'>
						<li class='a-list-item' v-for='(point, i) in item.details' :key='i'>{{point}}</li>
					</ul>
				</div>
			</div>
			<div class='i-card-action'>
				<div class='i-card-action-total'>
					<p class='a-card-total'>{{$helpers.amount(item.price, item.currency)}}</p>
				</div>
				<div class='i-card-action-group-buttons'>
					<v-button block xxsmall color='green white--text'
						:loading='loading_btn'
						@click.stop='$emit("add-order")'
					>{{$t('account.buttons.choose')}}</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["item", "onlyRead", "loading_btn"]
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.i-card {
		&-wrap {
			@include minw($grid-breakpoints-sm) {
				display: flex;
				align-items: stretch;
				min-height: 250px;
			}
		}
		&-container {
			flex: 1 1 100%;
			display: grid;
			@include minw($grid-breakpoints-md) {
				display: flex;
				align-items: flex-start;
			}
		}
		&-image {
			flex-shrink: 0;
			width: 216px;
			padding: 20px 15px 0;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				margin: 20px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin: 20px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		&-content {
			padding: 15px;
			flex: 1 1 100%;
			margin: 0;
			display: flex;
			flex-direction: column;
			@include minw($grid-breakpoints-xs) {
				padding: 0;
				margin: 25px * .8 30px * .8 35px * .8 15px * .8;
			}
			@include minw($grid-breakpoints-lg) {
				margin: 25px 30px 35px 15px;
			}
		}
		&-title {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 15px;
		}
		&-list {
			max-width: 350px;
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
		&-action {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			border-top: 1px solid rgba($black, .1);
			@include minw($grid-breakpoints-sm) {
				flex-direction: column;
				align-items: stretch;
				justify-content: flex-end;
				border-top: none;
				border-left: 1px solid rgba($black, .1);
				padding: 20px;
				flex: 0 0 300px * .85;
			}
			@include minw($grid-breakpoints-md) {
				flex: 0 0 300px * .9;
			}
			@include minw($grid-breakpoints-lg) {
				flex: 0 0 300px;
			}
			&-total {
				display: grid;
				@include minw($grid-breakpoints-sm) {
					text-align: center;
					margin-bottom: 30px * .8;
				}
				@include minw($grid-breakpoints-lg) {
					margin-bottom: 30px;
				}
			}
			&-group-buttons {
				width: 100%;
				@include maxw($grid-breakpoints-sm - 1) {
					max-width: 125px;
				}
			}
			.app-btn {
				font-size: 15px;
				padding-left: 12px;
				padding-right: 12px;
				@include minw($grid-breakpoints-sm) {
					padding: 0 4px;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
				@include minw($grid-breakpoints-lg) {
					font-size: 16px;
				}
				@include maxw($grid-breakpoints-sm - 1) {
					width: auto !important;
					min-width: 125px !important;
				}
				&-active {
					@include minw($grid-breakpoints-sm) {
						border: none;
					}
				}
			}
		}
		&-button {
			&-compare {
				font-weight: 400;
			}
		}
		&-footer {
			display: flex;
			align-items: center;
			@include maxw($grid-breakpoints-md - 1) {
				flex-wrap: wrap;
			}
		}
		&-mobile-btn {
			height: 40px;
			font-size: 14px;
			min-width: 125px;
		}
	}
</style>
