<template>
	<div>
		<div class='a-trips'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-trips__container">
					<trips-header @click='edit = $event' :progress='getProgress'>
						<template v-slot:status>
							<p class='a-trips__header-title bold' v-if='!lastResult'>{{$t('account.search.download_results')}}</p>
							<p class='a-trips__header-title' v-else-if='initialItems.length === 0'>{{$t('account.search.total_number_of_options')}}: <span>(0)</span></p>
							<p class='a-trips__header-title bold' v-else>{{$t('account.search.choose_want.insurance')}}</p>
						</template>
						<template v-slot:title>
							<p class='a-trips__header-title'>{{source.initialCountry.name}}</p>
						</template>
						<template v-slot:info>
							<p class='a-trips__header-text'>
								{{$moment(source.dateFrom).format("DD/MM/YYYY")}} - {{$moment(source.dateTo).format("DD/MM/YYYY")}}
								• {{source.adults}} {{$helpers.declOfNum(source.adults, $t('account.declOfNum.adults'))}}{{source.childrenCount ? `, ${source.childrenCount} ${$helpers.declOfNum(source.childrenCount, $t('account.declOfNum.children'))}` : null}}
							</p>
						</template>
					</trips-header>

					<trips-preloader
						name='insurance'
						animation='insurance'
						:title='!lastResult ? $t("account.search.preloader.insurance.checking") : $t("account.search.preloader.insurance.no_available")'
						:text='!lastResult ? null : $t("account.search.preloader.we_could_not_find_the_results")'
						v-if='!progress.some(() => true) || initialItems.length === 0'
					>
						<template v-slot:list v-if='lastResult'>
							<v-button small color='green white--text' :to="$i18nRoute({ name: 'insurance' })">{{$t('account.search.change_search')}}</v-button>
							<ul class='a-trips-search-list'>
								<li class='a-trips-search-list-item'>{{$t('account.search.try_choose_alternate_dates')}}</li>
							</ul>
						</template>
					</trips-preloader>

					<trips-skeleton-card
						v-else-if='loading'
					/>

					<template v-else>
						<div class='row flex-nowrap'>
							<div class="col-auto a-trips-sidebar-col-auto">
								<trips-sidebar
									v-model='mobile_sidebar'
									:items='initialItems'
									:filters='filters'
									:filtersItems='filtersItems'
									@update-filters='updateFilter($event)'
									@reset-filters='resetFilter($event)'
									@clear-filters='filters = {}'
								/>
							</div>
							<div class='col'>
								<div class="a-trips__main">
									<div class='row'>
										<div class="col">
											<v-button block xxsmall outline color='green' class='tablet-show a-card-mobile-btn' @click='mobile_sidebar = true' style='margin-bottom: 25px'>{{$t("account.sidebar.filters")}}</v-button>
										</div>
									</div>

									<div class="row">
										<div class="col"></div>
										<div class="col-auto">
											<div class='a-sorting'>
												<p class='a-sorting-title'>{{$t("account.buttons.sorting")}}:</p>
												<span class='a-sorting-list'>
													<span class='a-sorting-item' :class='{"active": active_sorting === "asc"}' @click='active_sorting = "asc", page = 1'>{{$t("account.search.insurance.sorting.cheap")}}</span>
													<span class='a-sorting-item' :class='{"active": active_sorting === "desc"}' @click='active_sorting = "desc", page = 1'>{{$t("account.search.insurance.sorting.expensive")}}</span>
												</span>
											</div>
										</div>
									</div>

									<div class='a-trips-content'>
										<div v-for='(item, i) in paginationItems' :key='i'>
											<trips-card
												:item='item'
												@add-order='addOrder(item)'
												:loading_btn='item.id === offerId && loading_btn'
											/>
										</div>

										<v-pagination
											:total='filtersItems.length'
											:page='page'
											:perPage='perPage'
											@update-pagination='page = $event'
											v-if='filtersItems.length'
										/>

										<trips-preloader
											name='insurance'
											animation='insurance'
											:title='$t("account.search.preloader.insurance.no_found")'
											v-if='paginationItems.length === 0'
										/>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>

		<trips-edit
			:title='$t("account.search.title.insurance")'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<a-home-insurance :edit='true' @update='edit = false, getInsuranceSearch()' />
			</template>
		</trips-edit>

		<trips-time @update-search='insuranceSearch'
			link='insurance'
			:title='$t("account.search.insurance.insurance")'
			:text='$t("account.search.insurance.insurances")'
			:edit="edit"
		/>
	</div>
</template>

<script>
	import TripsHeader from '@/views/account/search/components/header';
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsSkeletonCard from '@/views/account/search/insurance/skeleton-card';
	import TripsSidebar from '@/views/account/search/insurance/sidebar';
	import TripsCard from '@/views/account/search/insurance/card';
	import TripsEdit from '@/views/account/search/components/edit';
	import AHomeInsurance from "@/views/account/home/insurance";
	import TripsTime from '@/views/account/search/components/time';
	import { getInsuranceSearch, addOrder, insuranceSearch } from '@/services/request';
	import { disablePageScroll, enablePageScroll } from 'scroll-lock';
	import _ from 'lodash';

	export default {
		data: () => ({
			setTimeout: null,
			loading: false,
			initialItems: [],
      page: 1,
      perPage: 10,
			lastResult: false,
			progress: [],
			edit: false,
			filters: {},
			mobile_sidebar: false,
			active_sorting: 'asc',
			offerId: null,
			loading_btn: false
		}),
		components: {
			TripsHeader,
			TripsPreloader,
			TripsSkeletonCard,
			TripsSidebar,
			TripsCard,
			TripsEdit,
			AHomeInsurance,
			TripsTime
		},
		computed: {
			getProgress() {
				if(!this.progress) return;
				return this.progress.filter(el => el === true).length * 100 / this.progress.length;
			},
			source() {
				if(!this.$route.query.source) return;
				return JSON.parse(this.$route.query.source);
			},
			filtersItems() {
				return _.flattenDeep(Object.values(this.filters)).length ? this.updateItems() : this.initialItems;
			},
			paginationItems() {
				let items = _.sortBy(this.filtersItems.slice(this.page === 1 ? 0 : this.perPage * (this.page - 1), this.perPage * this.page), 'price');
				return this.active_sorting === "desc" ? items.reverse() : items;
			}
		},
		created() {
			this.getInsuranceSearch();
		},
		methods: {
			updateFilter($event) {
				this.filters = {...this.filters, ...$event};
				this.page = 1;
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
				this.updateItems();
			},
			resetFilter(name) {
				delete this.filters[name];
				this.updateFilter();
			},
			filterArray(array, filters) {
				const filterKeys = Object.keys(filters);
				return array.filter((item) => filterKeys.every((key) => {
					if(filters[key] === null || filters[key].length === 0) return item;

					if(key.indexOf("sliders") !== -1) {
						let DT = Math.ceil(_.get(item, key.replace('sliders.', '')));
						return filters[key][0] <= DT && filters[key][1] >= DT;
					}

					return filters[key].indexOf(String(_.get(item, key))) !== -1
				}));
			},
			updateItems() {
				return this.filterArray(this.initialItems, this.filters)
			},
			getInsuranceSearch(loading) {
				this.loading = loading ?? !(this.progress.some(() => true) && this.initialItems.length);

				getInsuranceSearch(this.$route.params.storageId).then(res => {
					if(res.data !== null) {
						this.initialItems = res.data.offers;

						this.lastResult = res.data.lastResult;
						this.progress = res.data.progress;

						if(!this.lastResult) {
							this.setTimeout = setTimeout(() => this.getInsuranceSearch(), 2500);
						}
						if(this.progress.some(() => true) && this.initialItems.length) {
							setTimeout(() => this.loading = false, 5000);
						}
					} else {
						this.initialItems = [];
						this.lastResult = true;
						this.progress = [];
					}
				})
			},
			addOrder({id}) {
				this.offerId = id
				this.loading_btn = true;

				addOrder({
					type: "insurance",
					details: {
						countryCode: this.source.initialCountry.code
					},
					orderId: this.$route.params.orderId,
					offerId: String(id),
					storageId: this.$route.params.storageId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => {
					this.loading_btn = false;
					this.offerId = null;
				})
			},
			insuranceSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;

				insuranceSearch(this.source).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.getInsuranceSearch(true);
					} else {
						this.$router.push({
							name: "insurance-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
						localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, insurance: this.form}));
					}
				})
			}
		},
		watch: {
			mobile_sidebar() {
				setTimeout(() => {
					const hideEl = document.querySelector('.a-sidebar')
					this.mobile_sidebar ? disablePageScroll(hideEl) : enablePageScroll(hideEl)
				}, 150)
			},
			"$route.params.storageId"() {
				this.edit = false;
				this.initialItems = [];
				this.page = 1;
				this.lastResult = false;
				this.progress = [];
				this.filters = {};

				this.getInsuranceSearch();
			}
		},
		beforeDestroy() {
			clearTimeout(this.setTimeout)
		}
	}
</script>
